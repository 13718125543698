import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ObrasSocialesService {
  respuesta;

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveObraSocial(obrasocial: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/socialworks', obrasocial);
  }

  getObraSocial(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/socialworks/' + data);
  }

  getObrasSociales(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/socialworks?page=' + page);
  }

  modificarObraSocial(id, obrasocial: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/socialworks/' + id, obrasocial);
  }
  deleteObraSocial(obrasocial_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/socialworks/' + obrasocial_id);  
   
  }
}
