import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.scss']
})
export class ConvenioComponent implements OnInit {
  url = "/agreements/notepages";
  convenios;
  comprobacionTabla;
  constructor(private frontData: FrontData) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.convenios = data;
      this.convenios.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.convenios.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }

}
