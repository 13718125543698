import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prestamo-caja',
  templateUrl: './prestamo-caja.component.html',
  styleUrls: ['./prestamo-caja.component.scss']
})
export class PrestamoCajaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
