import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-matricula',
  templateUrl: './matricula.component.html',
  styleUrls: ['./matricula.component.scss']
})
export class MatriculaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
