import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { PagesModule } from "./pages/pages.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { UrlService } from './service/url.service';
import { ProdGuardService } from './guards/prod-guard.service';
import { ProdInterceptorService } from './interceptors/prod-interceptor.service';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from "primeng/button";

import { ToastrModule } from 'ngx-toastr';

// Firebase + environment
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { environment } from '../environments/environment';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import { LoginComponent } from "./components/dashboard-admin/login/login.component";
import { DashboardComponent } from "./components/dashboard-admin/dashboard/dashboard.component";
import { FooterComponentAdmin } from "./components/dashboard-admin/footer/footer.component";
import { HeaderComponentAdmin } from "./components/dashboard-admin/header/header.component";
import { NuevoUsuarioComponent } from "./components/dashboard-admin/usuarios/nuevo-usuario/nuevo-usuario.component";
import { ModificarUsuarioComponent } from "./components/dashboard-admin/usuarios/modificar-usuario/modificar-usuario.component";
import { UsuariosComponent } from "./components/dashboard-admin/usuarios/usuarios.component";
import { MenuComponent } from "./components/dashboard-admin/menu/menu.component";
import { RevistasComponent } from "./components/dashboard-admin/revistas/revistas.component";
import { ModificarRevistaComponent } from './components/dashboard-admin/revistas/modificar-revista/modificar-revista.component';
import { NuevaRevistaComponent } from './components/dashboard-admin/revistas/nueva-revista/nueva-revista.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CapacitacionesComponent } from './components/dashboard-admin/capacitaciones/capacitaciones.component';
import { NuevaCapacitacionComponent } from './components/dashboard-admin/capacitaciones/nueva-capacitacion/nueva-capacitacion.component';
import { ModificarCapacitacionComponent } from './components/dashboard-admin/capacitaciones/modificar-capacitacion/modificar-capacitacion.component';
import { ConveniosComponent } from './components/dashboard-admin/convenios/convenios.component';
import { NuevoConvenioComponent } from './components/dashboard-admin/convenios/nuevo-convenio/nuevo-convenio.component';
import { ModificarConvenioComponent } from './components/dashboard-admin/convenios/modificar-convenio/modificar-convenio.component';
import { ConveniosInstitucionalesComponent } from './components/dashboard-admin/conveniosinstitucionales/conveniosinstitucionales.component';
import { NuevoConvenioInstitucionalComponent } from './components/dashboard-admin/conveniosinstitucionales/nuevo-convenio-institucional/nuevo-convenio-institucional.component';
import { ModificarConvenioInstitucionalComponent } from './components/dashboard-admin/conveniosinstitucionales/modificar-convenio-institucional/modificar-convenio-institucional.component';
import { ConveniosObrasSocialesComponent } from "./components/dashboard-admin/conveniosobrassociales/conveniosobrasociales.component";
import { NuevoConvenioObraSocialComponent } from "./components/dashboard-admin/conveniosobrassociales/nuevo-convenio-obra-social/nuevo-convenio-obra-social.component";
import { ModificarConvenioObraSocialComponent } from "./components/dashboard-admin/conveniosobrassociales/modificar-convenio-obra-social/modificar-convenio-obra-social.component";
import { ObrasSocialesComponent } from "./components/dashboard-admin/obrassociales/obrasociales.component";
import { NuevaObraSocialComponent } from "./components/dashboard-admin/obrassociales/nueva-obra-social/nueva-obra-social.component";
import { ModificarObraSocialComponent } from "./components/dashboard-admin/obrassociales/modificar-obra-social/modificar-obra-social.component";
import { NovedadesComponent } from "./components/dashboard-admin/novedades/novedades.component";
import { NuevaNovedadComponent } from "./components/dashboard-admin/novedades/nueva-novedad/nueva-novedad.component";
import { ModificarNovedadComponent } from "./components/dashboard-admin/novedades/modificar-novedad/modificar-novedad.component";
import { GrillaHonorarioComponent } from "./components/dashboard-admin/grilla_honorarios/grilla_honorario.component";
import { NuevaGrillaHonorarioComponent } from "./components/dashboard-admin/grilla_honorarios/nueva-grilla_honorario/nueva-grilla_honorario.component";
import { ModificarGrillaHonorarioComponent } from "./components/dashboard-admin/grilla_honorarios/modificar-grilla_honorario/modificar-grilla_honorario.component";
import { GaleriadeImagenesService } from "./service/galeria_de_imagenes.service";
import { GaleriaDeFotosComponent } from "./components/dashboard-admin/galeria/galeria_de_imagenes.component";
import { NuevaGaleriaDeFotosComponent } from "./components/dashboard-admin/galeria/nueva_galeria_de_imagenes/nueva_galeria_de_imagenes.component";
import { ModificarGaleriadeFotosComponent } from "./components/dashboard-admin/galeria/modificar_galeria_de_imagenes/modificar_galeria_de_imagenes.component";
import { ContactoComponent } from './components/dashboard-admin/contacto/contacto.component';
import { ModificarContactoComponent } from './components/dashboard-admin/contacto/modificar-contacto/modificar-contacto.component';
import { BibliotecaPrestamoComponent } from './components/dashboard-admin/biblioteca-prestamo/biblioteca-prestamo.component';
import { BibliotecaTiendaComponent } from './components/dashboard-admin/biblioteca-tienda/biblioteca-tienda.component';
import { NuevoPrestamoComponent } from './components/dashboard-admin/biblioteca-prestamo/nuevo-prestamo/nuevo-prestamo.component';
import { NuevaTiendaComponent } from './components/dashboard-admin/biblioteca-tienda/nueva-tienda/nueva-tienda.component';


export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    FooterComponentAdmin,
    HeaderComponentAdmin,
    NuevoUsuarioComponent,
    ModificarUsuarioComponent,
    UsuariosComponent,
    MenuComponent,
    RevistasComponent,
    ModificarRevistaComponent,
    NuevaRevistaComponent,
    CapacitacionesComponent,
    NuevaCapacitacionComponent,
    ModificarCapacitacionComponent,
    ConveniosComponent,
    NuevoConvenioComponent,
    ModificarConvenioComponent,
    ConveniosInstitucionalesComponent,
    NuevoConvenioInstitucionalComponent,
    ModificarConvenioInstitucionalComponent,
    ConveniosObrasSocialesComponent,
    NuevoConvenioObraSocialComponent,
    ModificarConvenioObraSocialComponent,
    ObrasSocialesComponent,
    NuevaObraSocialComponent,
    ModificarObraSocialComponent,
    NovedadesComponent,
    NuevaNovedadComponent,
    ModificarNovedadComponent,
    GrillaHonorarioComponent,
    NuevaGrillaHonorarioComponent,
    ModificarGrillaHonorarioComponent,
    GaleriaDeFotosComponent,
    NuevaGaleriaDeFotosComponent,
    ModificarGaleriadeFotosComponent,
    ContactoComponent,
    ModificarContactoComponent,
    BibliotecaPrestamoComponent,
    BibliotecaTiendaComponent,
    NuevoPrestamoComponent,
    NuevaTiendaComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    PagesModule,
    FontAwesomeModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    LottieModule.forRoot({ player: playerFactory }),
    SidebarModule,
    ButtonModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatProgressBarModule,
    AngularEditorModule
  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    UrlService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProdInterceptorService,
      multi: true,
    },
    ProdGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
