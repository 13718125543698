import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RevistasService } from 'src/app/service/revistas.service';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-revistas',
  templateUrl: './revistas.component.html',
  styleUrls: ['./revistas.component.scss']
})
export class RevistasComponent implements OnInit {
  seccion:string = "Revistas"
  isAdmin:boolean = false;
  revistas:any = {};
  comprobacionTabla:boolean = true;
  extension:string = "pdf"
  url: string;
  page = 1;
  last_page;
  total;
  constructor(private tokenService:TokenService, private revistaService:RevistasService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.revistaService.get(this.page).subscribe((respuesta) => {
      this.revistas = respuesta.Newspapers;
      this.last_page = respuesta.last_page;
      this.total = respuesta.total;
      if(this.revistas.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }

eliminarRevista(data,i){
  Swal.fire({
    title: '¿Está seguro que  desea eliminar este elemento?',  
    icon: 'warning',
    cancelButtonColor: "#929292", 
    confirmButtonColor: "#DD6B55", 
    showCancelButton: true,
    confirmButtonText: 'Eliminar',  
    cancelButtonText: 'Cancelar'
  }).
  then((result) => {
    if (result.isConfirmed) {
      this.revistaService.eliminarRegistro(data._id, data.file).then((url) => {
        this.revistas.splice(i,1);
        this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
      })
      .catch((error) => {
      });
      }
  })
}

modificarRevistaData(data) {
  this.router.navigate(['/modificar_revista/' + data._id]);
}
next(){
  if (this.page < this.last_page){
    this.page +=1
  }
  this.revistaService.getRevista(this.page).subscribe((respuesta) => {
    this.revistas = respuesta.trainings;
    this.last_page = respuesta.last_page;
    if(this.revistas.length == 0){
      this.comprobacionTabla = false
    } else {
      this.comprobacionTabla = true
    }
  });
}
prev(){ 
    if (this.page != 1){
      this.page -= 1;
    }
    this.revistaService.getRevista(this.page).subscribe((respuesta) => {
    this.revistas = respuesta.trainings;
    this.last_page = respuesta.last_page;
    if(this.revistas.length == 0){
      this.comprobacionTabla = false
    } else {
      this.comprobacionTabla = true
    }
  });
}

}
