import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ConveniosInstitucionalesService {
  respuesta;

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveConvenioInstitucional(convenioinstitucional: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/agreementsbusiness', convenioinstitucional);
  }

  getConvenioInstitucional(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/agreementsbusiness/' + data);
  }

  getConveniosInstitucionales(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/agreementsbusiness?page=' + page);
  }

  modificarConvenioInstitucional(id, convenioinstitucional: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/agreementsbusiness/' + id, convenioinstitucional);
  }
  deleteConvenioInstitucional(convenioinstitucional_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/agreementsbusiness/' + convenioinstitucional_id);  
   
  }
}
