import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Biblioteca } from 'src/app/models/biblioteca';
import { BibliotecaService } from 'src/app/service/biblioteca.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-nuevo-prestamo',
  templateUrl: './nuevo-prestamo.component.html',
  styleUrls: ['./nuevo-prestamo.component.scss']
})
export class NuevoPrestamoComponent implements OnInit {

  constructor(private tokenService : TokenService, private bibliotecaService: BibliotecaService, private _toastr: ToastrService, private router: Router) {
  }

  isAdmin = false;
  uploadProgress;
  typeBiblioteca;
  biblioteca : Biblioteca
  fileToUpload: File | null = null;
  fileName = "";
  archivoSubido: boolean = false;



  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.bibliotecaService.getTypeBiblioteca().subscribe((respuesta)=>{
      this.typeBiblioteca = respuesta
    })
  }


  public onSaveListado(data){
    this.biblioteca = new Biblioteca(data.title,data.description, data.file, data.librarytype);
    this.bibliotecaService.saveBiblioteca(this.biblioteca).subscribe((respuesta) => {
      this._toastr.success('La biblioteca '+ data.name +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/biblioteca']);
      });

  }
}
