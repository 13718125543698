import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../service/token.service';


@Injectable({
  providedIn: 'root'
})
export class ProdGuardService implements CanActivate {
  realRol: string;

  constructor(
    private tokenService: TokenService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const rol = this.tokenService.getAuthorities();
    if (rol){
      return true;
      }else {
      this.router.navigate(['/admin']);
      return false;
    }
    
   
  }

}
