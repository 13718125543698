import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { GrillaHonorarioService } from 'src/app/service/grilla-honorario.service';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grilla_honorario',
  templateUrl: './grilla_honorario.component.html',
  styleUrls: ['./grilla_honorario.component.scss']
})
export class GrillaHonorarioComponent implements OnInit {
  seccion:string = "Grilla de honorarios"
  isAdmin:boolean = false;
  grillaDeHonorarios:any = {};
  comprobacionTabla:boolean = true;
  extension:string = "pdf"
  url: string;
  page = 1;
  last_page;
  total;
  faPrev = faArrowLeft
  faNext = faArrowRight
  constructor(private tokenService:TokenService, private grillaHonorarioService:GrillaHonorarioService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.loadTable()
  }

loadTable(){
  this.grillaHonorarioService.getGrillaHonorarios(this.page).subscribe((respuesta) => {
    this.grillaDeHonorarios = respuesta.rategrid;
    this.last_page = respuesta.last_page;
    this.total = respuesta.total;
    if(this.grillaDeHonorarios.length == 0){
      this.comprobacionTabla = false
    } else {
      this.comprobacionTabla = true
    }
  });
}

eliminar(data,i){
  Swal.fire({
    title: '¿Está seguro que  desea eliminar este elemento?',  
    icon: 'warning',
    cancelButtonColor: "#929292", 
    confirmButtonColor: "#DD6B55", 
    showCancelButton: true,
    confirmButtonText: 'Eliminar',  
    cancelButtonText: 'Cancelar'
  }).
  then((result) => {
    if (result.isConfirmed) {
      this.grillaHonorarioService.deleteGrillaHonorario(data._id).subscribe((url) => {
        this.grillaDeHonorarios.splice(i,1);
        this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
      })
      }
  })
}

modificarData(data) {
  this.router.navigate(['/modificar-grilla_honorario/' + data._id]);
}
next(){
  if (this.page < this.last_page){
    this.page +=1
  }
  this.loadTable()
}
prev(){ 
    if (this.page != 1){
      this.page -= 1;
    }
   this.loadTable()
}

}
