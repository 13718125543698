import { Component, OnInit } from '@angular/core';
import { RevistasService } from 'src/app/service/revistas.service';
import { Revista } from 'src/app/models/revista';
import { TokenService } from 'src/app/service/token.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nueva-revista',
  templateUrl: './nueva-revista.component.html',
  styleUrls: ['./nueva-revista.component.scss']
})
export class NuevaRevistaComponent implements OnInit {
  isAdmin = false;
  uploadProgress;
  revista : Revista
  uploadFull: boolean = false;
  direccion : string;
  telefono : string;
  delivery : string;
  instagram : string;
  facebook : string;
  demora_local : string;
  demora_delivery : string;

  constructor(private tokenService : TokenService, private revistaService: RevistasService, private _toastr: ToastrService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
  }

  upload(data){
    $('#divProgressbar').css('display','block');
    this.revistaService.listadosFileUpload(data.target.files[0]).subscribe((respuesta) => {
      this.uploadProgress = respuesta;
      if (respuesta == 100){
        this.uploadFull = true;
      }
      });
  }

  public onSaveListado(data){
    this.revista = new Revista(data.title,"","");
    this.revistaService.guardarArchivoDB(this.revista).subscribe((respuesta) => {
      this._toastr.success('La revista '+ data.title +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/revistas']);
      });
  }
}
