import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RevistasService } from 'src/app/service/revistas.service';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';
import { NovedadesService } from 'src/app/service/novedades.service';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadesComponent implements OnInit {
  seccion:string = "Novedades"
  isAdmin:boolean = false;
  novedades:any = {};
  comprobacionTabla:boolean = true;
  extension:string = "pdf"
  url: string;
  page = 1;
  last_page;
  total;
  faPrev = faArrowLeft
  faNext = faArrowRight
  constructor(private tokenService:TokenService, private novedadesService:NovedadesService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.loadTable()
  }
  loadTable(){
    this.novedadesService.getNovedades(this.page).subscribe((respuesta) => {
      this.novedades = respuesta.news;
      this.last_page = respuesta.last_page;

      this.total = respuesta.total;
      if(this.novedades.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }
  modificarNovedadData(data){
    this.router.navigate(['/modificar-novedad/' + data._id]);
  }

  eliminarNovedad(data,i){
    Swal.fire({
      title: '¿Está seguro que  desea eliminar la novedad?'+data.file,  
      icon: 'warning',
      cancelButtonColor: "#929292", 
      confirmButtonColor: "#DD6B55", 
      showCancelButton: true,
      confirmButtonText: 'Eliminar',  
      cancelButtonText: 'Cancelar'
    }).
    then((result) => {
      if (result.isConfirmed) {
          this.novedadesService.deleteNovedad(data._id).subscribe((respuesta) => {
            this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
            this.loadTable()
          })
        }
    })
  }
    
  next(){
    if (this.page < this.last_page){
      this.page +=1
    }
    this.loadTable()
  }
  prev(){ 
      if (this.page != 1){
        this.page -= 1;
      }
    this.loadTable()
  }
}
