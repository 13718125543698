import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import Chart from "chart.js";
import { FormControl } from "@angular/forms";

import { FormService } from "src/app/service/form.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-landingpage",
  templateUrl: "landingpage.component.html",
  styleUrls: ['./landingpage.components.scss']
})
export class LandingpageComponent implements OnInit, AfterViewInit {
  i=0;
  focus;
  focus1;
  focus2;
  loader = true;
  public inputControl: FormControl;
  elements: any;
 
  constructor(private formService:FormService) {
   
  }
  ngAfterViewInit(): void {
    /*var contenedor = document.getElementById('contenedor');
      //contenedor.hidden
      contenedor.style.visibility = 'hidden';
      contenedor.style.opacity = '0';

      var wrapper = document.getElementById('wrapper');
      //wrapper.hidden
      wrapper.style.display = 'block';*/
  }

  ngOnInit() {
    // $(document).ready(function() {
      setTimeout(function() {
        $(".page-content").fadeIn(5000);},500);

      setTimeout(function() {
          $(".cssload-dots").fadeOut(5000);},500);

    // });
    
    if(document.querySelector('#container-slider')){
      setInterval(() => {
        this.fntExecuteSlide("next"); 
      }, 5000);
    }
    //------------------------------ LIST SLIDER -------------------------
    if(document.querySelector('.listslider')){
      let link = document.querySelectorAll(".listslider li a");
        link.forEach(function(link) {
          link.addEventListener('click', function(e){
            e.preventDefault();
            let item = this.getAttribute('itlist');
            let arrItem = item.split("_");
            this.fntExecuteSlide(arrItem[1]);
            return false;
        });
      });
    }

    
  }
  fntExecuteSlide(side){
    let parentTarget = document.getElementById('slider');
    let elements = parentTarget.getElementsByTagName('li');
    let curElement, nextElement;
    
    for(this.i=0; this.i<=elements.length;this.i++){
        if(elements[this.i].style.opacity == '1'){
          curElement = this.i;
          break;
        }
    }
    if(side == 'prev' || side == 'next'){

        if(side=="prev"){
            nextElement = (curElement == 0)?elements.length -1:curElement -1;
        }else{
            nextElement = (curElement == elements.length -1)?0:curElement +1;
        }
    }else{
        nextElement = side;
        side = (curElement > nextElement)?'prev':'next';

    }
    //RESALTA LOS PUNTOS
    let elementSel = document.getElementsByClassName("listslider")[0].getElementsByTagName("a");
    elementSel[curElement].classList.remove("item-select-slid");
    elementSel[nextElement].classList.add("item-select-slid");
    elements[curElement].style.opacity='0';
    elements[curElement].style.zIndex ='0';
    elements[nextElement].style.opacity='1';
    elements[nextElement].style.zIndex ='1';
  }
}

