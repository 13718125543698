// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://colegiopspvm-api.dndsystems.com.ar',
  firebaseConfig : {
    apiKey: "AIzaSyANO8u2hN4H2zJ79Yp6l8gXhk12D2Ecjz0",
    authDomain: "psicopedagogiavm-dd88f.firebaseapp.com",
    projectId: "psicopedagogiavm-dd88f",
    storageBucket: "psicopedagogiavm-dd88f.appspot.com",
    messagingSenderId: "775341885013",
    appId: "1:775341885013:web:c4909513442e57eff636dc"
  },
  bucket: "gs://psicopedagogiavm-dd88f.appspot.com"
};
