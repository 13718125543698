import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nueva-tienda',
  templateUrl: './nueva-tienda.component.html',
  styleUrls: ['./nueva-tienda.component.scss']
})
export class NuevaTiendaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
