import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/service/token.service';
import { faAlignJustify, faChartLine, faShoppingCart, faDollarSign, faUsers, faTruck, faBox, faUser, faSignOutAlt, faCashRegister, faClipboard, faTools, faPercent, faPager } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  faAlignJustify = faAlignJustify;
  faShoppingCart = faShoppingCart;
  faDollarSign = faDollarSign;
  faUsers = faUsers;
  faTrunk = faTruck;
  faBox = faBox;
  faUser = faUser;
  faSignOutAlt = faSignOutAlt;
  faCashRegister = faCashRegister;
  faTools = faTools;
  faClipboard = faClipboard;
  faChartLine = faChartLine
  faPercent = faPercent
  isPermision = false;
  isLogged = false;
  roles: string = "undefined";
  isAdmin = false;
  isUser = false;
  username: String = "undefined";
  faPage = faPager;

  constructor(private tokenService: TokenService, private router: Router) { }
  ngOnInit() {


    this.roles = this.tokenService.getAuthorities();
    this.username = this.tokenService.getUserName();

    if (this.roles == 'Administrador' || this.roles == 'SuperAdministrador') {
      this.isPermision = true;

    } else {
      this.isPermision = false;
    }


    if (this.tokenService.getToken()) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }

    //Toggle Click Function

    /*$('#sidebarCollapse').on('click', function() {
      $('#sidebar, #content').toggleClass('active');
    });*/
  }

  onLogOut(): void {
    this.tokenService.logOut();
    this.router.navigate(['/login']);
  }

}
