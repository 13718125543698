import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Biblioteca } from 'src/app/models/biblioteca';
import { BibliotecaService } from 'src/app/service/biblioteca.service';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-biblioteca-prestamo',
  templateUrl: './biblioteca-prestamo.component.html',
  styleUrls: ['./biblioteca-prestamo.component.scss']
})
export class BibliotecaPrestamoComponent implements OnInit {
  seccion:string = "Galeria de fotos"
  isAdmin:boolean = false;
  bibliotecas:any = {};
  comprobacionTabla:boolean = true;
  extension:string = "pdf"
  url: string;
  page = 1;
  last_page;
  total;
  faPrev = faArrowLeft
  faNext = faArrowRight
  constructor(private tokenService:TokenService, private bibliotecaService:BibliotecaService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.loadTable()
  }

loadTable(){
  this.bibliotecaService.getBibliotecas(this.page).subscribe((respuesta) => {
    this.bibliotecas = respuesta.librarys;
    this.last_page = respuesta.last_page;
    this.total = respuesta.total;
    if(this.bibliotecas.length == 0){
      this.comprobacionTabla = false
    } else {
      this.comprobacionTabla = true
    }
  });
}

eliminar(data,i){
  Swal.fire({
    title: '¿Está seguro que  desea eliminar este elemento?',  
    icon: 'warning',
    cancelButtonColor: "#929292", 
    confirmButtonColor: "#DD6B55", 
    showCancelButton: true,
    confirmButtonText: 'Eliminar',  
    cancelButtonText: 'Cancelar'
  }).
  then((result) => {
    if (result.isConfirmed) {
      this.bibliotecaService.deleteBiblioteca(data._id).subscribe((url) => {
        this.bibliotecas.splice(i,1);
        this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
      })
      }
  })
}

next(){
  if (this.page < this.last_page){
    this.page +=1
  }
  this.loadTable()
}
prev(){ 
    if (this.page != 1){
      this.page -= 1;
    }
   this.loadTable()
}
}
