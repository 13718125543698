import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DynamicViewsWithName } from 'src/app/models/dynamicViewsWithName';
import { Revista } from 'src/app/models/revista';
import { GaleriadeImagenesService } from 'src/app/service/galeria_de_imagenes.service'
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-modificar-galeria_de_imagenes',
  templateUrl: './modificar_galeria_de_imagenes.component.html',
  styleUrls: ['./modificar_galeria_de_imagenes.component.scss']
})
export class ModificarGaleriadeFotosComponent implements OnInit {
  isAdmin = false;
  uploadProgress;
  galeria_de_imagenes : any
  uploadFull: boolean = false;
  direccion : string;
  telefono : string;
  delivery : string;
  instagram : string;
  facebook : string;
  demora_local : string;
  demora_delivery : string;
  id;

  constructor(private tokenService : TokenService, private _activate: ActivatedRoute,private galeriadeImagenesService: GaleriadeImagenesService, private _toastr: ToastrService, private router: Router) {
    this._activate.params.subscribe((parametros) => {
      this.id = parametros['id'];
      console.log(this.id)
      this.galeriadeImagenesService.getGaleriadeFotos(this.id).subscribe((respuesta) => {
        this.galeria_de_imagenes = respuesta.rategrid;
        
      });
    });
  }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
  }


  public onEditListado(data){
    this.id = this.galeria_de_imagenes._id;
    this.galeria_de_imagenes = new DynamicViewsWithName(data.title,"",data.file);
    this.galeriadeImagenesService.modificarGaleriadeFotos(this.id, this.galeria_de_imagenes).subscribe((respuesta) => {
      this._toastr.success('La imagen'+ data.name +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/galeria_de_imagenes']);
      });
  }
}
