import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
import { environment } from 'src/environments/environment';
import { getFirestore} from 'firebase/firestore/lite';
import { initializeApp } from "firebase/app";
import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class FrontData {
url2;
  constructor(private _http : HttpClient, private _url : UrlService) { 
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const firebaseApp = getApp();
  }

  get(endpoint): Observable<any> {
    return this._http.get(this._url.getURLBase() + endpoint);
  }

  async listadosFileGet(fullPath : any) {
    const storage = getStorage();
    this.url2 = getDownloadURL(ref(storage, fullPath))
      .then((url) => {
        return url
      })
      .catch((error) => {
        return 
      });
    return this.url2
  }

}
