import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';
import { UrlService } from 'src/app/service/url.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {
  url = "/gallerys/notepages";
  galeria;
  comprobacionTabla;
  endpoint
  fotos;
  constructor(private frontData: FrontData, private _url: UrlService) { }

  ngOnInit(): void {
    this.endpoint = this._url.getURLBase() + "/"
    this.frontData.get(this.url).subscribe(data => {
      this.galeria = data;

      this.galeria.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        console.log(this.galeria)
      if(this.galeria.length == 0){
        this.comprobacionTabla = false
        
      } else {
        this.comprobacionTabla = true
      }
    });
  }

}
