import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class CapacitacionesService {
  respuesta;

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveCapacitacion(capacitacion: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/trainings',capacitacion);
  }

  getCapacitacion(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/trainings/' + data);
  }

  getCapacitaciones(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/trainings?page=' + page);
  }

  modificarCapacitacion(id, capacitacion: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/trainings/' + id,capacitacion);
  }
  deleteCapacitacion(capacitacion_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/trainings/'+ capacitacion_id);  
   
  }
}
