import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';
import { ObrasSocialesService } from 'src/app/service/obrassociales.service';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-obras-sociales',
  templateUrl: './obrassociales.component.html',
  styleUrls: ['./obrassociales.component.scss']
})
export class ObrasSocialesComponent implements OnInit {
  seccion:string = "Obras sociales"
  isAdmin:boolean = false;
  obrassociales:any = {};
  comprobacionTabla:boolean = true;
  extension:string = "pdf"
  url: string;
  page = 1;
  last_page;
  total;
  faPrev = faArrowLeft
  faNext = faArrowRight
  constructor(private tokenService:TokenService, private obrassocialesService:ObrasSocialesService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.obrassocialesService.getObrasSociales(this.page).subscribe((respuesta) => {
      this.obrassociales = respuesta.agreementssocialworks;
      this.last_page = respuesta.last_page;

      this.total = respuesta.total;
      if(this.obrassociales.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
      var i = 0
    });
  }

  modificarObraSocialData(data){
    this.router.navigate(['/modificar-obra-social/' + data._id]);
  }

  eliminarObraSocial(data,i){
    Swal.fire({
      title: '¿Está seguro que  desea eliminar la obra social?'+data.file,  
      icon: 'warning',
      cancelButtonColor: "#929292", 
      confirmButtonColor: "#DD6B55", 
      showCancelButton: true,
      confirmButtonText: 'Eliminar',  
      cancelButtonText: 'Cancelar'
    }).
    then((result) => {
      if (result.isConfirmed) {
          this.obrassocialesService.deleteObraSocial(data._id).subscribe((respuesta) => {})
          this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
        
        }
    })
  }
    
  next(){
    if (this.page < this.last_page){
      this.page +=1
    }
    this.obrassocialesService.getObrasSociales(this.page).subscribe((respuesta) => {
      this.obrassociales = respuesta.agreementsbusiness;
      this.last_page = respuesta.last_page;
      if(this.obrassociales.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }
  prev(){ 
      if (this.page != 1){
        this.page -= 1;
      }
      this.obrassocialesService.getObrasSociales(this.page).subscribe((respuesta) => {
      this.obrassociales = respuesta.trainings;
      this.last_page = respuesta.last_page;
      if(this.obrassociales.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }
}
