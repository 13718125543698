import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supervision-grupal',
  templateUrl: './supervision-grupal.component.html',
  styleUrls: ['./supervision-grupal.component.scss']
})
export class SupervisionGrupalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
