import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { PopoverModule } from "ngx-bootstrap/popover";

import { IndexComponent } from "./index/index.component";
import { ProfilepageComponent } from "./examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DatosSobreLaInstitucionComponent } from "./datos-sobre-la-institucion/datos-sobre-la-institucion.component";
import { MatriculaComponent } from "./matricula/matricula.component";
import { NuestraHistoriaComponent } from "./nuestra-historia/nuestra-historia.component";
import { SupervisionesComponent } from './supervisiones/supervisiones.component';
import { SegurosComponent } from './seguros/seguros.component';
import { AsesorContableComponent } from './asesor-contable/asesor-contable.component';
import { PrestamoTestComponent } from './prestamo-test/prestamo-test.component';
import { PrestamoCajaComponent } from './prestamo-caja/prestamo-caja.component';
import { SupervisionGrupalComponent } from './supervision-grupal/supervision-grupal.component';
import { TextosLegalesComponent } from './textos-legales/textos-legales.component';
import { SubComisionComponent } from './sub-comision/sub-comision.component';
import { CapacitacionComponent } from './capacitacion/capacitacion.component';
import { MediosDePagoComponent } from './medios-de-pago/medios-de-pago.component';
import { RevistaComponent } from './revista/revista.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { ConvenioComponent } from "./convenios/convenios.component";
import { ObraSocialComponent } from "./obrasocial/obrasocial.component";
import { NovedadComponent } from "./novedades/novedades.component";
import { ConvenioInstitucionComponent } from "./conveniosconinstituciones/convenioinstituciones.component";
import { GrillaDeHonorariosComponent } from "./grilla_de_honorarios/grilla_de_honorarios.component";
import { GaleriaComponent } from "./galeria/galeria.component";
import { BibliotecaPrestamosComponent } from "./biblioteca-prestamos/biblioteca-prestamo.component";
import { BibliotecaVentasComponent } from "./biblioteca-ventas/biblioteca-ventas.component";
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    SlickCarouselModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FontAwesomeModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
  ],
  declarations: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    HeaderComponent,
    FooterComponent,
    DatosSobreLaInstitucionComponent,
    MatriculaComponent,
    NuestraHistoriaComponent,
    SupervisionesComponent,
    SegurosComponent,
    AsesorContableComponent,
    PrestamoTestComponent,
    PrestamoCajaComponent,
    SupervisionGrupalComponent,
    TextosLegalesComponent,
    SubComisionComponent,
    CapacitacionComponent,
    MediosDePagoComponent,
    RevistaComponent,
    ConvenioComponent,
    ObraSocialComponent,
    NovedadComponent,
    ConvenioInstitucionComponent,
    GrillaDeHonorariosComponent,
    GaleriaComponent,
    BibliotecaPrestamosComponent,
    BibliotecaVentasComponent
  ],
  exports: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent
  ],
  providers: []
})
export class PagesModule { }
