import { Component, OnInit } from '@angular/core';
import { RevistasService } from 'src/app/service/revistas.service';
import { Revista } from 'src/app/models/revista';
import { TokenService } from 'src/app/service/token.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GaleriadeImagenesService } from 'src/app/service/galeria_de_imagenes.service'
import { UrlService } from 'src/app/service/url.service';
import { DynamicViews } from 'src/app/models/dynamicViews';
import { DynamicViewsWithName } from 'src/app/models/dynamicViewsWithName';
import { Galeria } from 'src/app/models/galeria';
@Component({
  selector: 'app-nueva_galeria_de_imagenes',
  templateUrl: './nueva_galeria_de_imagenes.component.html',
  styleUrls: ['./nueva_galeria_de_imagenes.component.scss']
})
export class NuevaGaleriaDeFotosComponent implements OnInit {
  isAdmin = false;
  uploadProgress;
  
  foto : Galeria
  fileToUpload: File | null = null;
  fileName = "";
  archivoSubido: boolean = false;

  constructor(private tokenService : TokenService, private galeriaDeImagenesService: GaleriadeImagenesService, private _toastr: ToastrService, private router: Router, private _url:UrlService) {
  }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
  }


  public onSaveListado(data){
    this.foto = new Galeria(this.fileName,data.name, data.gallery);
    this.galeriaDeImagenesService.saveGaleriadeFotos(this.foto).subscribe((respuesta) => {
      this._toastr.success('La foto '+ data.name +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/galeria_de_fotos']);
      });
  }

  getFile(files) {
    this.fileToUpload = files.target.files[0];
    const formData: FormData = new FormData();

    formData.append('file', this.fileToUpload);

    if (this.fileToUpload.size/1024 <= 5000){
      fetch(this._url.getURLBase() + '/files', {
        method: 'POST',
        body: formData
        }).then(response => response.json())
        .then((response) => {
          if(response.status == 200)
            {
              this.archivoSubido = true;
              this.fileName += response.filename
            }
        })
        .catch(err => err)
    }
    else{
      this._toastr.success('El tamaño del archivo es demasiado grande, intenta con uno menor a 5MB','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
    }

  }
}
