import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ConveniosService {
  respuesta;

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveConvenio(convenio: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/agreements', convenio);
  }

  getConvenio(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/agreements/' + data);
  }

  getConvenios(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/agreements?page=' + page);
  }

  modificarConvenio(id, convenio: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/agreements/' + id, convenio);
  }
  deleteConvenio(convenio_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/agreements/' + convenio_id);  
   
  }
}
