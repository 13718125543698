

export class Revista {
   title: string;
   description: string;
   file: string;
    constructor(   title: string,
        description: string,
        file: string ){
           this.title = title
           this.description = description
           this.file = file
    }
}
