import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class GrillaHonorarioService {

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveGrillaHonorario(GrillaHonorario: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/rategrid', GrillaHonorario);
  }

  getGrillaHonorario(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/rategrid/' + data);
  }

  getGrillaHonorarios(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/rategrid?page=' + page);
  }

  modificarGrillaHonorario(id, grillahonorario: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/rategrid/' + id, grillahonorario);
  }
  deleteGrillaHonorario(grillahonorario_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/rategrid/' + grillahonorario_id);  
   
  }
}
