import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class BibliotecaService {

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveBiblioteca(biblioteca: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/library', biblioteca);
  }

  getBiblioteca(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/library/' + data);
  }

  getBibliotecas(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/library?page=' + page);
  }

  modificarBiblioteca(id, biblioteca: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/library/' + id, biblioteca);
  }
  deleteBiblioteca(biblioteca_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/library/' + biblioteca_id);
  }

  getTypeBiblioteca(): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/libraryType/notepages/');
  }
}
