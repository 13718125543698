import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class NovedadesService {
  respuesta;

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveNovedad(novedad: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/news', novedad);
  }

  getNovedad(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/news/' + data);
  }

  getNovedades(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/news?page=' + page);
  }

  modificarNovedad(id, novedad: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/news/' + id, novedad);
  }
  deleteNovedad(novedad_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/news/' + novedad_id);  
   
  }
}
