import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';
import { ConveniosInstitucionalesService } from 'src/app/service/conveniosinstitucionales.service';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-convenios-institucionales',
  templateUrl: './conveniosinstitucionales.component.html',
  styleUrls: ['./conveniosinstitucionales.component.scss']
})
export class ConveniosInstitucionalesComponent implements OnInit {
  seccion:string = "Convenio con instituciones"
  isAdmin:boolean = false;
  conveniosinstitucionales:any = {};
  comprobacionTabla:boolean = true;
  extension:string = "pdf"
  url: string;
  page = 1;
  last_page;
  total;
  faPrev = faArrowLeft
  faNext = faArrowRight
  constructor(private tokenService:TokenService, private conveniosinstitucionalesService:ConveniosInstitucionalesService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.loadTable()
  }
loadTable(){
  this.conveniosinstitucionalesService.getConveniosInstitucionales(this.page).subscribe((respuesta) => {
    this.conveniosinstitucionales = respuesta.agreementsBusiness;
    this.last_page = respuesta.last_page;

    this.total = respuesta.total;
    if(this.conveniosinstitucionales.length == 0){
      this.comprobacionTabla = false
    } else {
      this.comprobacionTabla = true
    }
  });
}
  modificarConvenioInstitucionalData(data){
    this.router.navigate(['/modificar-convenio-institucional/' + data._id]);
  }

  eliminarConvenioInstitucional(data,i){
    Swal.fire({
      title: '¿Está seguro que  desea eliminar el convenio con esta institución?'+data.file,  
      icon: 'warning',
      cancelButtonColor: "#929292", 
      confirmButtonColor: "#DD6B55", 
      showCancelButton: true,
      confirmButtonText: 'Eliminar',  
      cancelButtonText: 'Cancelar'
    }).
    then((result) => {
      if (result.isConfirmed) {
          this.conveniosinstitucionalesService.deleteConvenioInstitucional(data._id).subscribe((respuesta) => {})
          this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
          this.loadTable()
        }
    })
  }
    
  next(){
    if (this.page < this.last_page){
      this.page +=1
    }
    this.loadTable()
  }
  prev(){ 
      if (this.page != 1){
        this.page -= 1;
      }
      this.loadTable()
  }
}
