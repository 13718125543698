import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RolesService {

  private roles: Rol[] = [];
  endpoint:string = '/roles/'

  constructor(private _http: HttpClient, private _url: UrlService) { }

  get(): Observable<any> {
    return this._http.get<any[]>(this._url.getURLBase() + this.endpoint);
  }

  getRol(id): Observable<any> {
    return this._http.get<any[]>(this._url.getURLBase() + this.endpoint + id);
  }
}

export interface Rol {
  _id: number;
  nombre: string;
}