import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Revista } from 'src/app/models/revista';
import { RevistasService } from 'src/app/service/revistas.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-modificar-revista',
  templateUrl: './modificar-revista.component.html',
  styleUrls: ['./modificar-revista.component.scss']
})
export class ModificarRevistaComponent implements OnInit {
  isAdmin = false;
  uploadProgress;
  revistas : any
  uploadFull: boolean = false;
  direccion : string;
  telefono : string;
  delivery : string;
  instagram : string;
  facebook : string;
  demora_local : string;
  demora_delivery : string;
  id;

  constructor(private tokenService : TokenService, private _activate: ActivatedRoute,private revistaService: RevistasService, private _toastr: ToastrService, private router: Router) {
    this._activate.params.subscribe((parametros) => {
      this.id = parametros['id'];
      this.revistaService.getRevista(this.id).subscribe((respuesta) => {
        this.revistas = respuesta.newspapers;
      });
    });
  }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
  }

  upload(data){
    $('#divProgressbar').css('display','block');
    this.revistaService.listadosFileUpload(data.target.files[0]).subscribe((respuesta) => {
      this.uploadProgress = respuesta;
      if (respuesta == 100){
        this.uploadFull = true;
      }
      });
  }

  public onEditListado(data){
    this.id = this.revistas._id;
    this.revistas = new Revista(data.title,"",data.file);
    this.revistaService.modificarArchivoDB(this.revistas, this.id).subscribe((respuesta) => {
      this._toastr.success('La revista '+ data.nombre +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/revistas']);
      });
  }
}
