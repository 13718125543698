

export class Biblioteca {
   title: string;
   description: string;
   file: string;
   librarytype: string
    constructor(title: string, description: string, file: string, librarytype:string ){
        this.title = title;
        this.description = description;
        this.file = file
        this.librarytype = librarytype
    }
}
