import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
@Injectable({
  providedIn: 'root'
})
export class MensajeService {

  constructor(private _http: HttpClient, private _url: UrlService) { }

  enviar(mensaje): Observable<any> {
    return this._http.post("https://email.dndsystems.com.ar/email/send_email", {
      "from":"dami.pramparo@gmail.com",
      "subject" : "Formulario de contacto - Colegio de Psicopedagogos Villa María",
      "text" :"<strong> Correo electronico: </strong>"+ mensaje.email+ "<br>" + "<strong>Teléfono: </strong>" + mensaje.phone +
      "<br> " + "<strong>Localidad: </strong>" + mensaje.localidad +
      "<br> " + "<strong>Nombre y Apellido: </strong>" + mensaje.name +
      "<br> " + "<strong>Mensaje: </strong>" + mensaje.message 
  });
  }

  getMenssage(): Observable<any> {
    return this._http.get(this._url.getURLBase() + "/mensaje");
  }
}
