import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { DynamicViews } from 'src/app/models/dynamicViews';
import { ConveniosService } from 'src/app/service/convenios.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-modificar-convenio',
  templateUrl: './modificar-convenio.component.html',
  styleUrls: ['./modificar-convenio.component.scss']
})
export class ModificarConvenioComponent implements OnInit {
  htmlContent: any;
  convenio: DynamicViews;
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'no',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  toolbarHiddenButtons: [
    [
      'undo',
      'redo',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertUnorderedList',
      'insertOrderedList',
      'heading',
      'fontName'
    ],
    [
      'fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
      'toggleEditorMode'
    ]
  ]
  id: any;
  constructor(private _activate: ActivatedRoute, private conveniosService: ConveniosService,private tokenService : TokenService, private _toastr: ToastrService, private router: Router) { 
    this._activate.params.subscribe((parametros) => {
      this.id = parametros['id'];
      this.conveniosService.getConvenio(this.id).subscribe((respuesta) => {
        this.convenio = respuesta.agreement;
      });
    });
  }

  ngOnInit(): void {
  }

  editSave(data){
    this.convenio = new DynamicViews(data.title,data.htmlContent,"");
    this.conveniosService.modificarConvenio(this.id, this.convenio).subscribe((respuesta) => {
      this._toastr.success('La convenio: "'+ data.title +'" se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/convenio']);
      });
  }

}
