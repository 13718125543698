import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supervisiones',
  templateUrl: './supervisiones.component.html',
  styleUrls: ['./supervisiones.component.scss']
})
export class SupervisionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
