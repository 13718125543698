import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';

@Component({
  selector: 'app-novedad',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadComponent implements OnInit {
  url = "/news/notepages";
  novedad;
  comprobacionTabla;
  constructor(private frontData: FrontData) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.novedad = data;
      this.novedad.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.novedad.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }

}
