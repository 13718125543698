import { Component, OnInit } from '@angular/core';

import{UrlService} from 'src/app/service/url.service'
import { Router, ActivatedRoute} from '@angular/router';
import {LoginUsuario} from 'src/app/models/login-usuario';
import {TokenService} from 'src/app/service/token.service';
import {LoginService} from 'src/app/service/login.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { RolesService } from 'src/app/service/roles.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLogged = false;
  isLoginFail = false;
  loginUsuario: LoginUsuario | undefined;

  public username: string = "";
  public password: string = "";
  roles: string[] =[];
  errMsj: string = "";
  id = "";
  flag: boolean = false;
  hide = true;
  
  constructor(
    private tokenService : TokenService,
    private urlService: UrlService,
    private loginService : LoginService,
    private router: Router,
    private _activate : ActivatedRoute,
    private rolServices : RolesService
  ) {}

  ngOnInit() {
    if(this.tokenService.getToken()){
      this.router.navigate(['/dashboard']);
    }
    this.isLogged = true;
    this.isLoginFail = false

    $(document).ready(function() {
      setTimeout(function() {
        $(".page-content").fadeIn(1500);},500);

      setTimeout(function() {
          $(".cssload-dots").fadeOut(1500);},500);
    });
  }

  onLogin(): void {
    this.loginUsuario = new LoginUsuario(this.username.toLowerCase(), this.password);

    this.loginService.login(this.loginUsuario).subscribe(
      data => {
        if(data.message = "Login Successfully" ){

          this.flag = data.config
          this.isLogged = true;
          this.isLoginFail = false;
          this.tokenService.setAuthorities(data.user.rol.name);
          
          this.tokenService.setToken(data.token);
          this.tokenService.setUserName(data.user.username);
      
          this.tokenService.setName(data.user.name);
          this.tokenService.setId(data.id);
          this.roles = data.authorities;
          this.router.navigate(['/dashboard']);

        }else{
          this.isLogged = false;
          this.isLoginFail = true;
          this.router.navigate(['/login']);

          Swal.fire({
            title: 'Usuario o Contraseña incorrecta',
            icon: 'warning',
            confirmButtonColor: "#F70C0C",
            confirmButtonText: 'Continuar',
          })
        }
      }
    )
  }
}