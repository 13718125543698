

export class Galeria {
   photo: string;
   description: string;
   order: number
   gallery:string
    constructor(photo: string, description: string, gallery: string){
        this.photo = photo;
        this.description = description;
        this.order = 0
        this.gallery = gallery
    }
}
