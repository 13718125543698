import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-revista',
  templateUrl: './revista.component.html',
  styleUrls: ['./revista.component.scss']
})
export class RevistaComponent implements OnInit {
  url = "/newspapers/notepages";
  url2;
  revistas;
  revistas_temp;
  comprobacionTabla
  constructor(private frontData: FrontData) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.revistas = data;
      this.revistas.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.revistas.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });

  }
  onClickDownloadPdf(data){
    this.frontData.listadosFileGet(data.file).then((url) => {
      this.downloadMyFile(url,data.title);
    })
    .catch((error) => {
    });
  
  }

  downloadMyFile(url, name){
    saveAs(url, name);
}

}
