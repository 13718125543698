import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';

@Component({
  selector: 'app-capacitacion',
  templateUrl: './capacitacion.component.html',
  styleUrls: ['./capacitacion.component.scss']
})
export class CapacitacionComponent implements OnInit {
  url = "/trainings/notepages";
  capacitaciones;
  comprobacionTabla;
  constructor(private frontData: FrontData) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.capacitaciones = data;
      this.capacitaciones.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.capacitaciones.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }

}
