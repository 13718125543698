import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { DynamicViews } from 'src/app/models/dynamicViews';
import { ConveniosService } from 'src/app/service/convenios.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-nuevo-convenio',
  templateUrl: './nuevo-convenio.component.html',
  styleUrls: ['./nuevo-convenio.component.scss']
})
export class NuevoConvenioComponent implements OnInit {
  htmlContent;
  convenio: DynamicViews;
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'no',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // uploadUrl: 'http://localhost:8080/src/assets/img',
    // // upload: (file: File) => {  }
    // uploadWithCredentials: false,
    // sanitize: true,
    // toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //   ['bold', 'italic'],
    //   ['fontSize']
    // ]
  };
  toolbarHiddenButtons: [
    [
      'undo',
      'redo',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertUnorderedList',
      'insertOrderedList',
      'heading',
      'fontName'
    ],
    [
      'fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
      'toggleEditorMode'
    ]
  ]
  id: any;
  constructor(private conveniosService: ConveniosService,private tokenService : TokenService, private _toastr: ToastrService, private router: Router) {
  }


  ngOnInit(): void {
  }

  onSave(data){
    this.convenio = new DynamicViews(data.title,data.htmlContent,"");
    this.conveniosService.saveConvenio(this.convenio).subscribe((respuesta) => {
      this._toastr.success('La convenio: "'+ data.title +'" se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/convenio']);
      });
  }

}

