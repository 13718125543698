import { Component, OnInit } from '@angular/core';
import { faBars, faCaretLeft, faBell } from '@fortawesome/free-solid-svg-icons';
import { FooterComponentAdmin } from '../footer/footer.component';


// import { ButtonModule } from 'primeng/button'
// import { SidebarModule } from 'primeng/sidebar';
// import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})
export class HeaderComponentAdmin implements OnInit {

  footer: FooterComponentAdmin;
  notifications = []
  productos_precio_compra_nulo = [];
  productos_precio_compra_nulo_true = true;
  faBars = faBars;
  faBell = faBell
  faCaretLeft = faCaretLeft;
  notificationsTrue = true;
  content;
  cantidadMaxima = false;
  cantidadMinima = false;
  cantidadNot: number = 0;
  unProducto = false;
  productoCostoNulo;
  visibleSidebar2;
  styleOBJAzul = {
    'background': '#0000ffb7',
    'text-align': 'center',
    'padding': '0% 0%',

    'margin': '3% 0%',
    'cursor': 'pointer'
  }
  styleOBJRojo = {
    'background': '#ff0000d7',
    'text-align': 'center',
    'padding': '0% 0%',

    'margin': '3% 0%',
    'cursor': 'pointer'
  }
  sidebar = {
    'background-color': 'rgb(255, 255, 255)'
  }
  styleOBJVerde = {
    'background': '#07c007ef',
    'text-align': 'center',
    'padding': '0% 0%',

    'margin': '3% 0%',
    'cursor': 'pointer'
  }

  constructor( //private sidebarModule: SidebarModule, private buttonModule: ButtonModule, private primengConfig: PrimeNGConfig
    ) {
  }

  ngOnInit(): void {
  //   this.notificationsHeader();
  //   this.primengConfig.ripple = true;
  // }
  // headerOculto() {
  //   $('.header').css('z-index', '1');
  //   this.visibleSidebar2 = true;


  }
  acomodarBoton() {

    if ($("#content").hasClass("active") == false) {

      $('#sidebar, #content').toggleClass('active');
      $('#sidebarCollapse').addClass('activo');
      $('#textoFooter').css('margin-right', '0rem');
      if (screen.width <= 768) {
        $('#contenedorDerecha').css('display', 'none');
      } else {
        $('#contenedorDerecha').css('display', 'block');
      }

    } else {
      $('#sidebar, #content').removeClass('active');
      $('#sidebarCollapse').removeClass('activo');
      $('#contenedorDerecha').css('display', 'block');
      $('#textoFooter').css('margin-right', '17rem');
      if (screen.width <= 768) {

        $('#imagen').css('width', '10%');
      }
    }



    /*if('#content == active'){
      $('#sidebar, #content').toggleClass('active');
      $('#sidebarCollapse').removeClass('activo');
      
    } else{
      $('#sidebar, #content').removeClass('active');
      $('#textoFooter').css('display','none');
    }

    $('#sidebarCollapse').addClass('activo');*/
    //VER COMO HACER PARA HACER PARA QUE EL BOTON FUNCIONE SIEMPRE

  }

  // notificationsHeader() {
  //   this.productoService.notificaciones().subscribe((respuesta) => {
  //     this.notifications = []
  //     respuesta.forEach(element => {
  //       if (element.cantidad_productos > 0) {
  //         this.notifications.push(element)
  //       }
  //     });
  //     if (this.notifications.length != 0) {
  //       this.notificationsTrue = false
  //     }
  //     if (this.notifications.length >= 11) {
  //       this.cantidadMaxima = true;
  //       this.cantidadNot = this.cantidadNot + 1
  //     } else {
  //       this.cantidadMinima = true;
  //       this.cantidadNot = this.notifications.length + this.productos_precio_compra_nulo.length;
  //     }
  //   });

  //   this.productoService.notificacionPrecioCompra().subscribe((respuesta) => {
  //     this.productos_precio_compra_nulo = []
  //     respuesta.forEach(element => {
  //       this.productos_precio_compra_nulo.push(element.nombre);

  //     });
  //     if (this.productos_precio_compra_nulo.length != 0) {
  //       this.productos_precio_compra_nulo_true = false
  //     }
  //     if (this.productos_precio_compra_nulo.length >= 11) {
  //       this.cantidadMaxima = true;
  //       this.cantidadNot = this.cantidadNot + 1
  //     } else {
  //       this.cantidadMinima = true;
  //       this.cantidadNot = this.notifications.length + this.productos_precio_compra_nulo.length;
  //     }

  //   });

  // }

}
