import { Component, OnInit, ViewChild} from '@angular/core';
import { faSun, faMoon} from '@fortawesome/free-solid-svg-icons';
import { TokenService } from 'src/app/service/token.service';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  saludo: any = [];
  saludo_:any = {}
  today = Date.now();
  date : any = Date.now();
  faSaludo;
  name: string = "undefined";
  username: string = "undefined";
  hoy = new Date();
  jstoday = '';
  constructor(private tokenService: TokenService) { 
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
  }

  ngOnInit(): void {
    this.date = formatDate(this.today, 'HH:MM', 'en-ES', 'UTC−0300');
    this.saludo_ = this.saludo_;
    this.saludo.push(this.saludar());
    this.name = this.tokenService.getName();
    //this.hoy = new Date().getDate();
  }

  saludar(){
    if (this.date <= '12:00' && this.date >= '06:00'){
      this.saludo_="Buen Día";
      this.faSaludo = faSun;
      $('.icon').css('color', '#ffe000');
    }else if ( this.date >'12:00' && this.date<= '20:00'){
      this.saludo_="Buenas Tardes";
      this.faSaludo = faSun;
      $('.icon').css('color', '#ffe000');
    }else{
      this.saludo_ = "Buenas Noches";
      this.faSaludo = faMoon;
      $('.icon').css('color', 'rgb(14 58 157)');
    }
    return this.saludo_;
  }
}