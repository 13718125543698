import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';
import { GrillaHonorarioService } from 'src/app/service/grilla-honorario.service';
import { UrlService } from 'src/app/service/url.service';

@Component({
  selector: 'app-grilla_de_honorarios',
  templateUrl: './grilla_de_honorarios.component.html',
  styleUrls: ['./grilla_de_honorarios.component.scss']
})
export class GrillaDeHonorariosComponent implements OnInit {
  url = "/rategrid/notepages";
  url2;
  grilla_de_honorarios;
  revistas_temp;
  comprobacionTabla
  url_file
  constructor(private frontData: FrontData, private grillaDeHonorarioService : GrillaHonorarioService, private _url: UrlService) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.grilla_de_honorarios = data;
      this.grilla_de_honorarios.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.grilla_de_honorarios.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });

  }
  
  onClickDownloadPdf(data){
      this.url_file = this._url.getURLBase() +"/" + data.file
      
      window.location.href = this.url_file
      window.open(this.url_file)
  
  }
}
