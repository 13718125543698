import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/landingpage/landingpage.component";
import { DatosSobreLaInstitucionComponent } from "./pages/datos-sobre-la-institucion/datos-sobre-la-institucion.component";
import { MatriculaComponent } from "./pages/matricula/matricula.component";
import { NuestraHistoriaComponent } from "./pages/nuestra-historia/nuestra-historia.component";
import { SupervisionesComponent } from "./pages/supervisiones/supervisiones.component";
import { SegurosComponent } from "./pages/seguros/seguros.component";
import { AsesorContableComponent } from "./pages/asesor-contable/asesor-contable.component";
import { PrestamoTestComponent } from "./pages/prestamo-test/prestamo-test.component";
import { PrestamoCajaComponent } from "./pages/prestamo-caja/prestamo-caja.component";
import { SupervisionGrupalComponent } from "./pages/supervision-grupal/supervision-grupal.component";
import { TextosLegalesComponent } from "./pages/textos-legales/textos-legales.component";
import { SubComisionComponent } from "./pages/sub-comision/sub-comision.component";
import { CapacitacionComponent } from "./pages/capacitacion/capacitacion.component";
import { MediosDePagoComponent } from "./pages/medios-de-pago/medios-de-pago.component";
import { RevistaComponent } from "./pages/revista/revista.component";
import { DashboardComponent } from "./components/dashboard-admin/dashboard/dashboard.component";
import { ProdGuardService } from "./guards/prod-guard.service";
import { LoginComponent } from "./components/dashboard-admin/login/login.component";
import { NuevoUsuarioComponent } from "./components/dashboard-admin/usuarios/nuevo-usuario/nuevo-usuario.component";
import { UsuariosComponent } from "./components/dashboard-admin/usuarios/usuarios.component";
import { RevistasComponent } from "./components/dashboard-admin/revistas/revistas.component";
import { ModificarUsuarioComponent } from "./components/dashboard-admin/usuarios/modificar-usuario/modificar-usuario.component";
import { NuevaRevistaComponent } from "./components/dashboard-admin/revistas/nueva-revista/nueva-revista.component";
import { ModificarRevistaComponent } from "./components/dashboard-admin/revistas/modificar-revista/modificar-revista.component";
import { CapacitacionesComponent } from "./components/dashboard-admin/capacitaciones/capacitaciones.component";
import { NuevaCapacitacionComponent } from "./components/dashboard-admin/capacitaciones/nueva-capacitacion/nueva-capacitacion.component";
import { ModificarCapacitacionComponent } from "./components/dashboard-admin/capacitaciones/modificar-capacitacion/modificar-capacitacion.component";
import {  ConveniosComponent } from "./components/dashboard-admin/convenios/convenios.component";
import { ModificarConvenioComponent } from "./components/dashboard-admin/convenios/modificar-convenio/modificar-convenio.component";
import { NuevoConvenioComponent } from "./components/dashboard-admin/convenios/nuevo-convenio/nuevo-convenio.component";
import { ConveniosObrasSocialesComponent } from "./components/dashboard-admin/conveniosobrassociales/conveniosobrasociales.component";
import { NuevoConvenioObraSocialComponent } from "./components/dashboard-admin/conveniosobrassociales/nuevo-convenio-obra-social/nuevo-convenio-obra-social.component";
import { ModificarConvenioObraSocialComponent } from "./components/dashboard-admin/conveniosobrassociales/modificar-convenio-obra-social/modificar-convenio-obra-social.component";
import { ConveniosInstitucionalesComponent } from "./components/dashboard-admin/conveniosinstitucionales/conveniosinstitucionales.component";
import { NuevoConvenioInstitucionalComponent } from "./components/dashboard-admin/conveniosinstitucionales/nuevo-convenio-institucional/nuevo-convenio-institucional.component";
import { ModificarConvenioInstitucionalComponent } from "./components/dashboard-admin/conveniosinstitucionales/modificar-convenio-institucional/modificar-convenio-institucional.component";
import { ObrasSocialesComponent } from "./components/dashboard-admin/obrassociales/obrasociales.component";
import { NuevaObraSocialComponent } from "./components/dashboard-admin/obrassociales/nueva-obra-social/nueva-obra-social.component";
import { ModificarObraSocialComponent } from "./components/dashboard-admin/obrassociales/modificar-obra-social/modificar-obra-social.component";
import { NovedadesComponent } from "./components/dashboard-admin/novedades/novedades.component";
import { NuevaNovedadComponent } from "./components/dashboard-admin/novedades/nueva-novedad/nueva-novedad.component";
import { ModificarNovedadComponent } from "./components/dashboard-admin/novedades/modificar-novedad/modificar-novedad.component";
import { ConvenioComponent } from "./pages/convenios/convenios.component";
import { ObraSocialComponent } from "./pages/obrasocial/obrasocial.component";
import { NovedadComponent } from "./pages/novedades/novedades.component";
import { ConvenioInstitucionComponent } from "./pages/conveniosconinstituciones/convenioinstituciones.component";
import { GrillaDeHonorariosComponent } from "./pages/grilla_de_honorarios/grilla_de_honorarios.component";
import { GrillaHonorarioComponent } from "./components/dashboard-admin/grilla_honorarios/grilla_honorario.component";
import { NuevaGrillaHonorarioComponent } from "./components/dashboard-admin/grilla_honorarios/nueva-grilla_honorario/nueva-grilla_honorario.component";
import { ModificarGrillaHonorarioComponent } from "./components/dashboard-admin/grilla_honorarios/modificar-grilla_honorario/modificar-grilla_honorario.component";
import { GaleriaDeFotosComponent } from "./components/dashboard-admin/galeria/galeria_de_imagenes.component";
import { NuevaGaleriaDeFotosComponent } from "./components/dashboard-admin/galeria/nueva_galeria_de_imagenes/nueva_galeria_de_imagenes.component";
import { ModificarGaleriadeFotosComponent } from "./components/dashboard-admin/galeria/modificar_galeria_de_imagenes/modificar_galeria_de_imagenes.component";
import { GaleriaComponent } from "./pages/galeria/galeria.component";
import { ModificarContactoComponent } from "./components/dashboard-admin/contacto/modificar-contacto/modificar-contacto.component";
import { BibliotecaPrestamoComponent } from "./components/dashboard-admin/biblioteca-prestamo/biblioteca-prestamo.component";
import { NuevoPrestamoComponent } from "./components/dashboard-admin/biblioteca-prestamo/nuevo-prestamo/nuevo-prestamo.component";
import { BibliotecaPrestamosComponent } from "./pages/biblioteca-prestamos/biblioteca-prestamo.component";
import { BibliotecaVentasComponent } from "./pages/biblioteca-ventas/biblioteca-ventas.component";
const routes: Routes = [
  { path: "", component: LandingpageComponent },
  { path: "quienes-somos", component: DatosSobreLaInstitucionComponent },
  { path: "matricula", component: MatriculaComponent },
  { path: "nuestra-historia", component: NuestraHistoriaComponent },
  { path: "covision", component: SupervisionesComponent },
  { path: "seguros", component: SegurosComponent },
  { path: "asesor-contable", component: AsesorContableComponent },
  // { path: "prestamo-responsable-de-test", component: PrestamoTestComponent },
  { path: "sub-comision", component: SubComisionComponent },
  { path: "marcos-legales", component: TextosLegalesComponent },
  { path: "capacitacion", component: CapacitacionComponent },
  { path: "medios-de-pago", component: MediosDePagoComponent },
  { path: "revista", component: RevistaComponent },
  { path: "convenios", component: ConvenioComponent},
  { path: "convenios_con_instituciones", component: ConvenioInstitucionComponent},
  { path: "obrasociales", component: ObraSocialComponent},
  { path: "novedades", component: NovedadComponent},
  { path: "grilla_de_honorarios", component: GrillaDeHonorariosComponent},
  { path: "galeria", component: GaleriaComponent},
  { path: "prestamo", component: BibliotecaPrestamosComponent},
  { path: "venta", component: BibliotecaVentasComponent},

  { path: "dashboard", component: DashboardComponent, canActivate : [ProdGuardService]  },

  { path: "usuarios", component: UsuariosComponent, canActivate : [ProdGuardService]  },

  { path: "nuevo_usuario", component: NuevoUsuarioComponent, canActivate : [ProdGuardService]  },

  { path: "modificar_usuario/:id", component: ModificarUsuarioComponent, canActivate : [ProdGuardService]  },
  { path: "login", component: LoginComponent},
  { path: "revistas", component: RevistasComponent},
  { path: "modificar_revista/:id", component: ModificarRevistaComponent, canActivate : [ProdGuardService]  },
  { path: "nueva_revista", component: NuevaRevistaComponent, canActivate : [ProdGuardService]  },
  // { path: "supervisiones-grupales", component: SupervisionGrupalComponent },

  { path: "capacitaciones", component: CapacitacionesComponent, canActivate : [ProdGuardService] },
  { path: "nueva-capacitacion", component: NuevaCapacitacionComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-capacitacion/:id", component: ModificarCapacitacionComponent, canActivate : [ProdGuardService]},

  { path: "convenio", component: ConveniosComponent, canActivate : [ProdGuardService] },  
  { path: "nuevo-convenio", component: NuevoConvenioComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-convenio/:id", component: ModificarConvenioComponent, canActivate : [ProdGuardService]  },

  { path: "conveniosobrassociales", component: ConveniosObrasSocialesComponent, canActivate : [ProdGuardService] },  
  { path: "nuevo-convenio-obra-social", component: NuevoConvenioObraSocialComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-convenio-obra-social/:id", component: ModificarConvenioObraSocialComponent, canActivate : [ProdGuardService]  },

  { path: "conveniosinstitucionales", component: ConveniosInstitucionalesComponent, canActivate : [ProdGuardService] },  
  { path: "nuevo-convenio-institucional", component: NuevoConvenioInstitucionalComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-convenio-institucional/:id", component: ModificarConvenioInstitucionalComponent, canActivate : [ProdGuardService]  },

  { path: "novedad", component: NovedadesComponent, canActivate : [ProdGuardService] },  
  { path: "nueva-novedad", component: NuevaNovedadComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-novedad/:id", component: ModificarNovedadComponent, canActivate : [ProdGuardService]  },

  { path: "grilla_honorarios", component: GrillaHonorarioComponent, canActivate : [ProdGuardService] },  
  { path: "nueva-grilla_honorario", component: NuevaGrillaHonorarioComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-grilla_honorario/:id", component: ModificarGrillaHonorarioComponent, canActivate : [ProdGuardService]  },

  { path: "galeria_de_fotos", component: GaleriaDeFotosComponent, canActivate : [ProdGuardService] },  
  { path: "nueva-foto", component: NuevaGaleriaDeFotosComponent, canActivate : [ProdGuardService]  },
  { path: "modificar-foto/:id", component: ModificarGaleriadeFotosComponent, canActivate : [ProdGuardService]  },

  { path: "modificar-datosdecontacto", component: ModificarContactoComponent, canActivate : [ProdGuardService]  },

  { path: "biblioteca", component: BibliotecaPrestamoComponent, canActivate : [ProdGuardService] },  
  { path: "nueva-biblioteca", component: NuevoPrestamoComponent, canActivate : [ProdGuardService]  },
  {path: '**', redirectTo: ""}

  //{ path: "home", component: IndexComponent },
  // { path: "profile", component: ProfilepageComponent },
  //{ path: "register", component: RegisterpageComponent },
  //{ path: "admin", component: LoginComponent },
  //{ path: "dashboard", component: DashboardComponent, canActivate: [ProdGuardService] },
  //{ path: "novedades", component: NovedadesComponent, canActivate: [ProdGuardService] },
  //{ path: "nueva-novedad", component: NuevaNovedadComponent, canActivate: [ProdGuardService] },
  //{ path: "usuarios", component: UsuariosComponent, canActivate: [ProdGuardService] },
  //{ path: "nuevo-usuario", component: NuevoUsuarioComponent, canActivate: [ProdGuardService] },
  //{ path: "mensajes", component: MensajesComponent, canActivate: [ProdGuardService] },
  //{ path: "listados", component: ListadosComponent },
  //{ path: "nuevo-listado", component: NuevoListadoComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: []
})
export class AppRoutingModule { }
