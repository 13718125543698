import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioService } from 'src/app/service/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { RolesService } from 'src/app/service/roles.service';

@Component({
  selector: 'app-modificar-usuario',
  templateUrl: './modificar-usuario.component.html',
  styleUrls: ['./modificar-usuario.component.css']
})
export class ModificarUsuarioComponent implements OnInit {

  usuarios:any = {};
  private id;
  usuario: Usuario[] = [];
  nombre: string;
  apellido: string;
  legajo: number;
  sucursal: any;
  sucursales: any;
  rol: any;
  roles: any;
  tipos_usuario: any;
  username: string;
  password: string;

  constructor(private UsuarioService: UsuarioService, private rolesService: RolesService,private router :Router, private _activate : ActivatedRoute, private toastr : ToastrService) {
    this._activate.params.subscribe((parametros) => {
      this.id = parametros['_id'];
      this.UsuarioService.getUsuario(parametros['_id']).subscribe((respuesta) => {
          this.usuarios = respuesta;
        });
    });
  }

  ngOnInit(): void {
    this.rolesService.get().subscribe((data) => {
      this.roles = data
    });
  }

  onEdit(data){
    if (!data.valid){
      this.nombre = data.nombre;
      this.apellido = data.apellido;
      this.username = data.username;
      this.password = data.password;
      this.rol = data.rol;
      this.sucursal = data.sucursal;

      if (this.usuarios.password != data.password ){
        this.password = data.password;
      }
      this.usuarios = new Usuario(this.username,this.nombre, this.apellido,this.password, this.rol);
      this.UsuarioService.modificarUsuario(this.usuarios, this.id).subscribe((data) => {
        this.toastr.success('Usuario '+ data.nombre +' modificado correctamente.','Correcto', {closeButton: true});
      });

      this.router.navigate(['/usuarios']);
    }else{
      this.toastr.error('Por favor, revise los campos obligatorios ','Error');
    }
  }

}
