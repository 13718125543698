import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  //URL del back

  //private URL:string = "https://137.184.96.43:8001";
  private URL: string = environment.API_URL;

  constructor(private httpClient : HttpClient) { }
  
  getURLBase() : string{
    return this.URL;
  }
}
