import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medios-de-pago',
  templateUrl: './medios-de-pago.component.html',
  styleUrls: ['./medios-de-pago.component.scss']
})
export class MediosDePagoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
