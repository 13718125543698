
export class Usuario {
    username: string;
    name: string;
    last_name: string;
    password: string;
    rol:string;
    activo:boolean = true
  constructor(
    username: string,
    name: string,
    last_name: string,
    password: string,
    rol: string
    ){
      this.username= username,this.name = name,this.last_name = last_name,this.password = password, this.rol = rol
      
    }


}
