import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveoficinas(oficinas: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/offices',oficinas);
  }

  getoficinas(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/offices/' + data);
  }

  getoficinases(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/offices?page=' + page);
  }

  modificaroficinas(id, oficinas: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/offices/' + id,oficinas);
  }
  deleteoficinas(oficinas_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/offices/'+ oficinas_id);  
   
  }
}
