import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from './url.service';
import { CookieService } from 'ngx-cookie-service';  

const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AuthAuthorities';
const ID_KEY = 'AuthId';
const NAME = 'AuthName';
const CI = 'CI';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  static getname(): string {
    throw new Error('Method not implemented.');
  }
  roles : Array<string> = [];

  constructor(private _http : HttpClient , private _url : UrlService, private cookieService: CookieService ) { }
  public setToken(token:string): void {
    this.cookieService.delete(TOKEN_KEY);
    this.cookieService.set(TOKEN_KEY,token, 0.0416667);
  }

  public getToken() : string {
    return this.cookieService.get(TOKEN_KEY);
  }

  public setUserName(userName:string): void {
    this.cookieService.delete(USERNAME_KEY);
    this.cookieService.set(USERNAME_KEY, userName, 0.0416667);
  }

  public setId(id:string): void {
    this.cookieService.delete(ID_KEY);
    this.cookieService.set(ID_KEY, id, 0.0416667);
  }

  public setName(name:string): void{
    this.cookieService.set(NAME, name, 0.0416667);
  }
  
  public getId(): string {
    return this.cookieService.get(ID_KEY);
  }

  public getUserName() : string {
    return this.cookieService.get(USERNAME_KEY);
  }

  public getName(): string {
    return this.cookieService.get(NAME);
  }

  public setAuthorities(authorities:string): void {
    this.cookieService.delete(AUTHORITIES_KEY);
    this.cookieService.set(AUTHORITIES_KEY, authorities, 0.0416667);
  }

  public getAuthorities(): string{
    return this.cookieService.get(AUTHORITIES_KEY);
  }

  public logOut(): void{
    this.cookieService.delete(USERNAME_KEY);
    this.cookieService.delete(AUTHORITIES_KEY);
    this.cookieService.delete(TOKEN_KEY);
    this._http.post(this._url.getURLBase() + '/login/logout' ,this.getToken());
  }


}
