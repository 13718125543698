import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class GaleriadeImagenesService {

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveGaleriadeFotos(GaleriadeFotos: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/gallerys', GaleriadeFotos);
  }

  getGaleriadeFoto(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/gallerys/' + data);
  }

  getGaleriadeFotos(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/gallerys?page=' + page);
  }

  modificarGaleriadeFotos(id, galeria: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/gallerys/' + id, galeria);
  }
  deleteGaleriadeFotos(galeria_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/gallerys/' + galeria_id);
  }
}
