import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biblioteca-tienda',
  templateUrl: './biblioteca-tienda.component.html',
  styleUrls: ['./biblioteca-tienda.component.scss']
})
export class BibliotecaTiendaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
