import { Component, OnInit } from '@angular/core';
import { ContactoService } from 'src/app/service/contacto.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-modificar-contacto',
  templateUrl: './modificar-contacto.component.html',
  styleUrls: ['./modificar-contacto.component.scss']
})
export class ModificarContactoComponent implements OnInit {
  oficinas;
  oficina;
  constructor(private contactoService: ContactoService, private _toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.contactoService.getoficinases(1).subscribe(respuesta =>{ 
      this.oficinas = respuesta.offices[0]
    })
  }
  editSave(data){
    this.contactoService.modificaroficinas(this.oficinas._id, data).subscribe((respuesta) => {
      this._toastr.success('El contacto de: "'+ data.name +'" se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/dashboard']);
      });
  }
}
