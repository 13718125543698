import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DynamicViewsWithName } from 'src/app/models/dynamicViewsWithName';
import { Revista } from 'src/app/models/revista';
import { GrillaHonorarioService } from 'src/app/service/grilla-honorario.service';
import { RevistasService } from 'src/app/service/revistas.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-modificar-grilla_honorario',
  templateUrl: './modificar-grilla_honorario.component.html',
  styleUrls: ['./modificar-grilla_honorario.component.scss']
})
export class ModificarGrillaHonorarioComponent implements OnInit {
  isAdmin = false;
  uploadProgress;
  grilla_honorario : any
  uploadFull: boolean = false;
  direccion : string;
  telefono : string;
  delivery : string;
  instagram : string;
  facebook : string;
  demora_local : string;
  demora_delivery : string;
  id;

  constructor(private tokenService : TokenService, private _activate: ActivatedRoute,private grillaHonorarioService: GrillaHonorarioService, private _toastr: ToastrService, private router: Router) {
    this._activate.params.subscribe((parametros) => {
      this.id = parametros['id'];
      console.log(this.id)
      this.grillaHonorarioService.getGrillaHonorario(this.id).subscribe((respuesta) => {
        this.grilla_honorario = respuesta.rategrid;
        
      });
    });
  }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
  }


  public onEditListado(data){
    this.id = this.grilla_honorario._id;
    this.grilla_honorario = new DynamicViewsWithName(data.title,"",data.file);
    this.grillaHonorarioService.modificarGrillaHonorario(this.id, this.grilla_honorario).subscribe((respuesta) => {
      this._toastr.success('La grilla '+ data.name +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/grilla_honorarios']);
      });
  }
}
