import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';

@Component({
  selector: 'app-convenioinstituciones',
  templateUrl: './convenioinstituciones.component.html',
  styleUrls: ['./convenioinstituciones.component.scss']
})
export class ConvenioInstitucionComponent implements OnInit {
  url = "/agreementsbusiness/notepages";
  convenioinstituciones;
  comprobacionTabla;
  constructor(private frontData: FrontData) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.convenioinstituciones = data;
      this.convenioinstituciones.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.convenioinstituciones.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }

}
