import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';
import { UrlService } from 'src/app/service/url.service';

@Component({
  selector: 'app-biblioteca-ventas',
  templateUrl: './biblioteca-ventas.component.html',
  styleUrls: ['./biblioteca-ventas.component.scss']
})
export class BibliotecaVentasComponent implements OnInit {
  url = "/library/notepages";
  url2;
  biblioteca_venta;
  revistas_temp;
  comprobacionTabla
  url_file
  constructor(private frontData: FrontData, private _url: UrlService) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.biblioteca_venta = data;
      console.log(this.biblioteca_venta)
      this.biblioteca_venta.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.biblioteca_venta.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
      this.biblioteca_venta.forEach((obj, i)=> {
        if(obj.librarytype.name != "tienda"){
          this.biblioteca_venta.splice(i,1)
        }
    });
    });

  }
  
  onClickDownloadPdf(data){
      this.url_file = this._url.getURLBase() +"/" + data.file
      
      window.location.href = this.url_file
      window.open(this.url_file)
  
  }
}
