import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario';
import { RolesService } from 'src/app/service/roles.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})
export class NuevoUsuarioComponent implements OnInit {
  usuario: any;
  nombre: string;
  apellido: string;
  roles: any;
  rol:any;
  sucursal: any;
  sucursales: any;
  username: string;
  password: string;

  constructor(
    private usuarioService: UsuarioService,
    private rolesService: RolesService,
    private _toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.rolesService.get().subscribe((data) => {
      this.roles = data.roles
    });
  }

  onSave(data){
    if (!data.valid) {
      if (data.password == data.password_confirm) {
        this.nombre = data.nombre;
        this.apellido = data.apellido;
        this.username = data.username;
        this.password = data.password;
        this.rol = data.rol;
      
        this.usuario = new Usuario(this.username, this.nombre, this.apellido,this.password, this.rol);
        this.usuarioService.guardarUsuario(this.usuario).subscribe((data) => {
          this._toastr.success('El usuario '+ data.nombre +' se ha guardado correctamente.','Correcto', {
            timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
        });

        this.router.navigate(['/usuarios']);
      } else {
        this._toastr.error('Contraseñas diferentes, verifique de ingresar ambas contraseñas iguales','Error');
      }
    } else {
      this._toastr.error('Revise los campos','Error');
    }

  }
}
