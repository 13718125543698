import { Component, OnInit } from '@angular/core';
import { ContactoService } from 'src/app/service/contacto.service';
import { FrontData } from 'src/app/service/front-data.service';
import { MensajeService } from 'src/app/service/mensaje.service';
import Swal from 'sweetalert2';
declare let Email: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  email
  localidad
  phone
  name
  message
  buttonMessage = "Enviar"
  contacto;
  constructor(private mensajesService : MensajeService, private contactoService: FrontData) { }

  ngOnInit(): void {
    this.contactoService.get("/offices/notpages").subscribe((respuesta) => {
      this.contacto = respuesta.offices[0]
      console.log(this.contacto)
    })
  }

  enviarEmail(data){  
    this.mensajesService.enviar(data).subscribe((respuesta) => {
      this.buttonMessage = "Enviando..."
      if (respuesta.message == 'Enviado'){
        Swal.fire(
          '¡Mensaje Enviado!',
          'Nos comunicaremos contigo, a la brevedad',
          'success'
        )
        this.email = ""
        this.localidad = ""
        this.phone = ""
        this.name = ""
        this.message = ""
        this.buttonMessage = "Enviar"
      }
      });
    
  };

}
