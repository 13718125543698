

export class DynamicViewsWithName {
   name: string;
   description: string;
   file: string;
    constructor(name: string, description: string, file: string ){
        this.name = name;
        this.description = description;
        this.file = file
    }
}
