import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  constructor() { }  

  ngOnInit(): void {
    window.onload = function(){
      //var nav = document.getElementById('contenedor');
      //nav.style.display = 'block';
      //nav.style.opacity = '0';
      AOS.init();

    } 
  }
}
