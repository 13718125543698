import { Component, OnInit } from '@angular/core';
import { FrontData } from 'src/app/service/front-data.service';

@Component({
  selector: 'app-obrasocial',
  templateUrl: './obrasocial.component.html',
  styleUrls: ['./obrasocial.component.scss']
})
export class ObraSocialComponent implements OnInit {
  url = "/agreementssocialworks/notepages";
  obrasocial;
  comprobacionTabla;
  constructor(private frontData: FrontData) { }

  ngOnInit(): void {
    this.frontData.get(this.url).subscribe(data => {
      this.obrasocial = data;
      this.obrasocial.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if(this.obrasocial.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
    });
  }

}
