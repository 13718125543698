import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ConveniosObrasSocialesService {
  respuesta;

  constructor(private _http : HttpClient, private _url : UrlService) { }

  saveConvenioObraSocial(convenioobrasocial: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/agreementssocialworks', convenioobrasocial);
  }

  getConvenioObraSocial(data): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/agreementssocialworks/' + data);
  }

  getConveniosObrasSociales(page): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/agreementssocialworks?page=' + page);
  }

  modificarConvenioObraSocial(id, convenioobrasocial: any): Observable<any> {
    return this._http.put(this._url.getURLBase() + '/agreementssocialworks/' + id, convenioobrasocial);
  }
  deleteConvenioObraSocial(convenioobrasocial_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/agreementssocialworks/' + convenioobrasocial_id);  
   
  }
}
